import React, { useState, useEffect, memo } from "react";
import ReusableDialog from "../../components/Dialog";
import { DialogActions, DialogContent } from "@mui/material";
import { ChatBubbleLeftEllipsisIcon as CommentIcon } from "@heroicons/react/20/solid";
import axiosInstance from "../../utils/axiosInstance";
import { TakeDownCommentProps } from "../../types";
import { useAccount, useMsal } from "@azure/msal-react";
import moment from "moment";
import { useSnackbarContext } from "../../providers/SnackbarProvider";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";

type Props = {
  takeDownID: string;
};

const Comments = memo((props: Props) => {
  const { showSnackbar } = useSnackbarContext();
  const [open, setOpen] = useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [newComment, setNewComment] = useState("");
  const [takeDownComment, setTakeDownComment] = useState<TakeDownCommentProps>({
    TakeDownId: parseInt(props.takeDownID),
    Comment: "",
    isActive: true,
    CreatedDateTime: new Date(),
    CreatedBy: account?.name,
    ModifiedDateTime: new Date(),
    ModifiedBy: account?.name,
  });

  const [comments, setComments] = useState<
    {
      id: number;
      takedownId: number;
      comment: string;
      isActive: boolean;
      createdDatetime: string;
      createdBy: string;
      modifiedDatetime: string;
      modifiedBy: string;
    }[]
  >([]);

  const loadComments = (id: string) => {
    axiosInstance
      .get(`/api/GetTakeDownComments/${id}`)
      .then((res) => {
        setComments(
          res.data.results.filter(
            (c: { isActive: boolean }) => c.isActive === true
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const saveComment = (comment: string) => {
    const updatedTakeDownComment = {
      ...takeDownComment,
      Comment: comment.trim(),
      CreatedDateTime: new Date(),
      ModifiedDateTime: new Date(),
    };

    axiosInstance
      .post(`/api/CreateTakeDownComment`, updatedTakeDownComment)
      .then((res) => {
        if (res.status === 200 && (res?.data?.results ?? -1) > 0) {
          setComments([
            {
              id: res.data.results,
              takedownId: updatedTakeDownComment.TakeDownId,
              comment: updatedTakeDownComment.Comment,
              isActive: updatedTakeDownComment.isActive,
              createdDatetime: moment
                .utc(updatedTakeDownComment.CreatedDateTime)
                .toLocaleString(),
              createdBy: updatedTakeDownComment.CreatedBy || "Title Company",
              modifiedDatetime: moment
                .utc(updatedTakeDownComment.ModifiedDateTime)
                .toLocaleString(),
              modifiedBy: updatedTakeDownComment.ModifiedBy || "",
            },
            ...comments,
          ]);

          setNewComment("");
        } else {
          showSnackbar("There was an error creating a comment", "error");
        }
      });
  };

  const handleAddNewCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewComment(event.target.value);
  };

  const handleAddNewCommentKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && newComment.trim() !== "") {
      saveComment(newComment);
    }
  };

  const btnAddCommentClicked = () => {
    if (newComment.trim() !== "") {
      saveComment(newComment);
    }
  };

  useEffect(() => {
    if (open && props.takeDownID) {
      // Load comments only when the dialog opens and a takedown ID is present
      loadComments(props.takeDownID);
    }
  }, [open, props.takeDownID]);

  return (
    <>
      <ReusableDialog
        type="icon"
        icon={<CommentIcon className="h-6 w-6 text-primary-500" />}
        setIsOpen={setOpen}
        setIsClosed={() => {
          setOpen(false);
          setComments([]);
        }}
        isOpen={open}
        maxWidth={"md"}
        content={
          <>
            <DialogContent
              sx={{
                padding: 0,
                minHeight: 400,
                overflowX: "hidden", // Add this to prevent horizontal overflow
              }}
            >
              <div className="bg-white  py-4 antialiased">
                <div className="w-full mx-auto px-4">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-lg lg:text-xl font-bold text-gray-900">
                      Count ({comments.length})
                    </h2>
                  </div>

                  {comments.length ? (
                    comments.map((comment) => (
                      <article
                        key={comment.id}
                        className="p-6 mb-3 text-base bg-white border-t border-gray-200"
                      >
                        <footer className="flex flex-wrap justify-between items-center mb-2">
                          <div className="flex flex-wrap items-center">
                            <p className="inline-flex items-center mr-3 mb-2 text-sm text-gray-900  font-semibold">
                              <CommentIcon className="w-6 h-6 mr-2 text-primary-500 flex-shrink-0" />
                              <span className="break-all">
                                {comment.createdBy}
                              </span>
                            </p>
                            <p className="text-sm text-gray-600">
                              <time dateTime={comment.createdDatetime}>
                                {moment
                                  .utc(comment.createdDatetime)
                                  .format("L")}
                              </time>
                            </p>
                          </div>
                        </footer>
                        <p className="text-gray-500break-words">
                          {comment.comment}
                        </p>
                      </article>
                    ))
                  ) : (
                    <div className="flex items-center justify-center h-64">
                      <p className="text-gray-500">No Comments</p>
                    </div>
                  )}
                </div>
              </div>
            </DialogContent>

            <DialogActions className="p-0">
              <div className="flex flex-1 border-t border-gray-200">
                <div className="w-full bg-white">
                  <div className="flex items-center p-2">
                    <textarea
                      className="flex-grow px-3 py-2 text-sm text-gray-900  bg-transparent border-0 focus:ring-0 focus:outline-none resize-none"
                      rows={1}
                      value={newComment}
                      // @ts-ignore
                      onChange={handleAddNewCommentChange}
                      placeholder="Add a comment..."
                      onKeyDown={handleAddNewCommentKeyDown}
                    />
                    <button
                      className="p-2 text-primary-500 rounded-full hover:bg-blue-100  disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={btnAddCommentClicked}
                      disabled={newComment.trim() === ""}
                    >
                      <PaperAirplaneIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            </DialogActions>
          </>
        }
        buttonText="Create"
        title="Comments"
      />
    </>
  );
});

export default Comments;
