import React, { useState, useEffect } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  ListItemButton,
  Stack,
} from "@mui/material";
import { PaperClipIcon as AttachmentIcon } from "@heroicons/react/20/solid";
import axiosInstance from "../../utils/axiosInstance";
import moment from "moment";
import NoRows from "../../components/DataGrid/animations/NoRows";
import { useSnackbarContext } from "../../providers/SnackbarProvider";
import Typography from "@mui/material/Typography";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useDropzone } from "react-dropzone";

type Props = {
  takeDownID: string;
};

const Attachments = (props: Props) => {
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbarContext();

  const [attachments, setAttachments] = React.useState<
    {
      id: number;
      attachmentName: string;
      sasUrl: string;
      takedownId: number;
      isActive: boolean;
      createdDatetime: string;
      createdBy: string;
      modifiedDatetime: string;
      modifiedBy: string;
    }[]
  >([]);

  const sortedAttachments = [...attachments].sort((a, b) => {
    // Convert the createdDatetime strings to Date objects for comparison
    const dateA = new Date(a.createdDatetime);
    const dateB = new Date(b.createdDatetime);
    //@ts-ignore
    return dateB - dateA; // This will sort the array in descending order
  });

  const allowedMimeTypes = [
    "text/plain",
    "application/pdf",
    "image/png",
    "image/jpeg",
  ];

  const allowedExtensions = ["txt", "pdf", "png", "jpg", "jpeg"];

  const handleUpload = (acceptedFiles: File[]) => {
    const maxSizeInBytes = 20 * 1024 * 1024; // 20MB

    const invalidFiles = acceptedFiles.filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return (
        !allowedMimeTypes.includes(file.type) ||
        !fileExtension ||
        !allowedExtensions.includes(fileExtension)
      );
    });

    if (invalidFiles.length > 0) {
      showSnackbar(
        "Invalid file type(s). Please upload only supported file types (txt, pdf, png, jpg, jpeg).",
        "error"
      );
      return;
    }

    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > maxSizeInBytes
    );

    if (oversizedFiles.length > 0) {
      showSnackbar(
        `${oversizedFiles.length} file(s) exceed the maximum size of 20MB`,
        "error"
      );
      return;
    }

    showSnackbar("Uploading Attachment", "info");
    const uploadPromises = acceptedFiles.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axiosInstance.post(
        `/api/UploadAttachment?takedownId=${props.takeDownID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    });

    Promise.all(uploadPromises)
      .then(() => {
        loadAttachments(props.takeDownID);
        showSnackbar("All attachments uploaded successfully", "success");
      })
      .catch((error) => {
        console.error(`Error uploading attachments: ${error}`);
        showSnackbar("Error uploading attachments", "error");
      });
  };

  const loadAttachments = (id: string) => {
    axiosInstance
      .get(`/api/GetTakeDownAttachments/${id}`)
      .then((res) => {
        setAttachments(
          res.data.results.filter(
            (c: { isActive: boolean }) => c.isActive === true
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (open && props.takeDownID) {
      // Load comments only when the dialog opens and a takedown ID is present
      loadAttachments(props.takeDownID);
    }
  }, [open, props.takeDownID]);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    handleUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        showSnackbar(
          `Invalid file type: ${file.file.type || "unknown"}`,
          "error"
        );
      });
    },
    maxSize: 20 * 1024 * 1024, // 20MB
  });

  return (
    <>
      <ReusableDialog
        type="icon"
        icon={<AttachmentIcon className="h-6 w-6 text-primary-500" />}
        setIsOpen={setOpen}
        setIsClosed={() => {
          setOpen(false);
          setAttachments([]);
        }}
        isOpen={open}
        maxWidth={"lg"}
        content={
          <>
            <DialogContent
              sx={{
                padding: 0,
                height: "60vh",
                overflow: "hidden",
              }}
            >
              <Grid container spacing={0} sx={{ height: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 3,
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    {...getRootProps()}
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "2px dashed #ccc",
                      borderRadius: 2,
                      backgroundColor: "aliceblue",
                      transition: "all 0.3s ease",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#e6f3ff",
                        borderColor: "#2196f3",
                      },
                    }}
                  >
                    <input {...getInputProps()} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 3,
                        textAlign: "center",
                      }}
                    >
                      <AttachmentIcon className="h-12 w-12 text-primary-500 mb-2" />
                      <Typography variant="h6" gutterBottom>
                        {isDragActive ? "Drop files here" : "Drag and drop files here"}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        or click to select files
                      </Typography>
                    </Box>
                  </Box>
                  <FormHelperText
                    sx={{
                      mt: 2,
                      textAlign: "center",
                      fontSize: "0.9rem",
                    }}
                  >
                    Max File Size: 20MB | Supported types: .txt, .pdf, .png,
                    .jpg, .jpeg
                  </FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    maxHeight: "100%", // Ensure the Grid item has a max height
                    overflow: "hidden", // Hide overflow here to prevent the entire grid from scrolling
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      border: "1px solid rgba(0,0,0,0.12)",
                    }}
                  >
                    {sortedAttachments.length ? (
                      <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                          overflow: "auto",
                          maxHeight: "100%",
                          padding: 2,
                        }}
                      >
                        {sortedAttachments.map((attachment) => (
                          <Box
                            key={attachment.id}
                            sx={{
                              borderBottom: "1px solid #e5e7eb",
                              "&:last-child": {
                                borderBottom: "none",
                              },
                            }}
                          >
                            <ListItemButton
                              href={attachment.sasUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              alignItems="flex-start"
                              sx={{
                                padding: 2,
                                "&:hover": {
                                  backgroundColor: "#f3f4f6",
                                },
                              }}
                            >
                              <Box sx={{ width: "100%", overflow: "hidden" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    mb: 1,
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    component="span"
                                    sx={{
                                      fontWeight: "medium",
                                      display: "flex",
                                      alignItems: "center",
                                      wordBreak: "break-word",
                                      flex: 1,
                                      marginRight: 2,
                                    }}
                                  >
                                    <PaperClipIcon
                                      style={{
                                        width: 20,
                                        height: 20,
                                        marginRight: 8,
                                        flexShrink: 0,
                                      }}
                                    />
                                    {attachment.attachmentName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ flexShrink: 0 }}
                                  >
                                    {moment
                                      .utc(attachment.createdDatetime)
                                      .format("L")}
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{ wordBreak: "break-word" }}
                                >
                                  {attachment.createdBy}
                                </Typography>
                              </Box>
                            </ListItemButton>
                          </Box>
                        ))}
                      </Stack>
                    ) : (
                      <Box p={8}>
                        <NoRows title="No attachments" />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions
              sx={{
                padding: 0,
              }}
            ></DialogActions>
          </>
        }
        buttonText="Create"
        title="Attachments"
      />
    </>
  );
};

export default Attachments;
