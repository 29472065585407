/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import {
  ChevronRightIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  PaperClipIcon,
  PlusIcon,
  TrashIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { useSnackbarContext } from "../../providers/SnackbarProvider";
import { useMsal } from "@azure/msal-react";
import { useDropzone } from "react-dropzone";
import { Client } from "@microsoft/microsoft-graph-client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type FormValues = {
  region: string;
  type: string;
  projectNumber: string;
  projectName: string;
  section: string;
  addresses: {
    address: string;
    jobNumber: string;
    lot: string;
    block: string;
    price: string;
  }[];
  addressesOpen: boolean;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Create() {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = accounts[0];
  const { showSnackbar } = useSnackbarContext();

  const [loading, setLoading] = useState(false);
  const [availableRegions, setAvailableRegions] = useState<any[]>([]);
  const [projects, setProjects] = useState<any[]>([]);
  const [sections, setSections] = useState<any[]>([]);
  const [jobNumbers, setJobNumbers] = useState<any[]>([]);
  const [values, setValues] = useState<FormValues>({
    region: "",
    type: "",
    projectNumber: "",
    projectName: "",
    section: "",
    addresses: [],
    addressesOpen: true,
  });
  const [addressState, setAddressState] = useState({
    address: "",
    lot: "",
    block: "",
    price: "",
    jobNumber: "",
  });
  const [addressError, setAddressError] = useState<string>("");
  const [projectError, setProjectError] = useState<string>("");
  const [sectionError, setSectionError] = useState<string>("");
  const [showMaxCharWarning, setShowMaxCharWarning] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>("");
  const [comments, setComments] = useState<string[]>([]);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [attachments, setAttachments] = useState<
    Array<{
      name: string;
      size: number;
      type: string;
      lastModified: number;
    }>
  >([]);
  const [activeTab, setActiveTab] = useState<string>("Comments");
  const [userPhoto, setUserPhoto] = useState<string | null>(null);
  const [isManualModeDialogOpen, setIsManualModeDialogOpen] = useState(false);
  const [manualMode, setManualMode] = useState<boolean>(false);
  const [brixProjectId, setBrixProjectId] = useState<string>("");

  useEffect(() => {
    const fetchUserPhoto = async () => {
      if (accounts[0]) {
        try {
          const accessToken = await instance.acquireTokenSilent({
            scopes: ["User.Read"],
            account: accounts[0],
          });

          const graphClient = Client.init({
            authProvider: (done) => {
              done(null, accessToken.accessToken);
            },
          });

          const photo = await graphClient.api("/me/photo/$value").get();

          const photoUrl = URL.createObjectURL(photo);
          setUserPhoto(photoUrl);
        } catch (error) {
          console.error("Error fetching user photo:", error);
        }
      }
    };

    fetchUserPhoto();
  }, [accounts, instance]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      handleFileUpload(file);
    });
  }, []);

  const handleFileUpload = (file: File) => {
    const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
    const allowedFileTypes = [
      "text/plain",
      "application/pdf",
      "image/png",
      "image/jpeg",
    ];
    const allowedExtensions = [".txt", ".pdf", ".png", ".jpg", ".jpeg"];

    // Check file type
    if (!allowedFileTypes.includes(file.type)) {
      showSnackbar(
        "Invalid file type. Please upload a txt, pdf, png, or jpeg file.",
        "error"
      );
      return;
    }

    // Check file extension
    const fileExtension = file.name.toLowerCase().split(".").pop();
    if (!fileExtension || !allowedExtensions.includes(`.${fileExtension}`)) {
      showSnackbar(
        "Invalid file extension. Please upload a txt, pdf, png, or jpeg file.",
        "error"
      );
      return;
    }

    // Check file size
    if (file.size > maxSizeInBytes) {
      showSnackbar("File exceeds maximum size of 20MB", "error");
      return;
    }

    // If all checks pass, add the file to attachments
    setAttachments((prev) => [
      ...prev,
      {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      },
    ]);
    showSnackbar("File added successfully", "success");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        showSnackbar(
          `Invalid file type: ${file.file.type || "unknown"}`,
          "error"
        );
      });
    },
    maxSize: 20 * 1024 * 1024, // 20MB
  });

  const addComment = () => {
    if (!newComment.trim().length) return;
    setComments([newComment.trim(), ...comments]);
    setNewComment("");
  };

  const removeComment = (index: number) => {
    setComments(comments.filter((_, i) => i !== index));
  };

  const handleSubmit = async (_values: FormValues) => {
    if (values.addresses.length === 0) {
      setAddressError("At least 1 address is required");
      return;
    }
    if (values.projectName.length === 0) {
      setProjectError("Project Name is required");
      return;
    }
    setProjectError("");
    setAddressError("");
    setSectionError("");
    createTakedown(_values);
  };

  const handleExitManualMode = () => {
    if (
      confirm(
        "Are you sure you want to exit Manual Mode? This will reset your progress."
      )
    ) {
      setManualMode(false);
      // Reset form state here
      setValues({
        region: "",
        type: "",
        projectNumber: "",
        projectName: "",
        section: "",
        addresses: [],
        addressesOpen: true,
      });
      setAddressState({
        address: "",
        lot: "",
        block: "",
        price: "",
        jobNumber: "",
      });
      setBrixProjectId("");
      // ... reset other relevant state variables
    }
  };

  const createTakedown = async (_values: FormValues) => {
    setLoading(true);
    try {
      await axiosInstance
        .post("/api/CreateTakedown", {
          typeId: _values.type === "cash" ? 2 : 1,
          titleStatusId: 1,
          region: _values.region,
          projectNumber: values.projectNumber,
          projectName: values.projectName,
          brixProjectId: manualMode
            ? brixProjectId
              ? parseInt(brixProjectId)
              : null
            : parseInt(selectedProject?.projectId as string),
          section: values.section.toString(),
          guaranteeFileNumber: "",
          isActive: true,
          createdBy: account?.name || "",
          modifiedBy: account?.name || "",
          takedownLots: values.addresses.map((address) => ({
            jobNumber: address.jobNumber,
            addressNumber: "",
            addressStreet: address.address,
            block: address.block,
            price: address.price,
            lot: address.lot,
            isActive: true,
            createdBy: account?.name || "",
            modifiedBy: account?.name || "",
          })),
        })
        .then(async (res) => {
          const { results: strTakedownId } = res.data;
          if (comments.length) {
            comments.forEach((comment) => {
              axiosInstance
                .post("/api/CreateTakeDownComment", {
                  takedownId: parseInt(strTakedownId),
                  comment: comment,
                  isActive: true,
                  createdDateTime: new Date(),
                  createdBy: account?.name || "",
                  modifiedBy: account?.name || "",
                  modifiedDatetime: new Date(),
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          }

          if (attachments.length) {
            showSnackbar("Uploading Attachments", "success");
            const uploadPromises = attachments.map((attachment) => {
              return new Promise<void>((resolve, reject) => {
                // Create a new File object from the attachment data
                // @ts-ignore
                const file = new File([attachment], attachment.name, {
                  type: attachment.type,
                  lastModified: attachment.lastModified,
                });

                const formData = new FormData();
                formData.append("file", file);

                axiosInstance
                  .post(
                    `/api/UploadAttachment?takedownId=${strTakedownId}`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      resolve();
                    } else {
                      reject(`There was an error uploading the attachment`);
                    }
                  })
                  .catch((err) => {
                    reject(
                      `There was an error uploading the attachment: ${err}`
                    );
                  });
              });
            });

            try {
              await Promise.all(uploadPromises);
              showSnackbar("All attachments uploaded successfully", "success");
            } catch (error) {
              showSnackbar("Error uploading attachments", "error");
              console.error(`Error uploading attachments: ${error}`);
            }
          }

          return res;
        })
        .then((res) => {
          if (res.data.httpStatusCode === 500) {
            showSnackbar("Error creating takedown", "error");
          } else {
            showSnackbar("Successfully created takedown", "success");
            navigate("/edit/" + res.data.results);
          }
        });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const loadRegions = async () => {
    axiosInstance
      .get(`/api/getRegions`)
      .then((res) => {
        const regions = res.data.results;
        setAvailableRegions(
          regions.map((region: string) => ({
            value: region,
            text: region,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadProjects = async () => {
    if (!values.region) return;
    axiosInstance
      .get(`/api/GetProjectsbyRegion/${values.region}`)
      .then((res) => {
        setProjects(
          res.data.results.sort(
            (a: { projectName: string }, b: { projectName: any }) =>
              a.projectName.localeCompare(b.projectName)
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function customSort(array: string[]): string[] {
    return array.sort((a, b) => {
      const numA = Number(a);
      const numB = Number(b);

      const isANumber = !isNaN(numA) && isFinite(numA);
      const isBNumber = !isNaN(numB) && isFinite(numB);

      // Both are numeric strings: sort as numbers
      if (isANumber && isBNumber) {
        return numA - numB;
      }

      // One is numeric, the other is non-numeric: numeric strings first
      if (isANumber) return -1;
      if (isBNumber) return 1;

      // Both are non-numeric strings: alphanumeric strings before alphabetic strings
      const aIsAlphanumeric = /\d/.test(a);
      const bIsAlphanumeric = /\d/.test(b);

      if (aIsAlphanumeric && !bIsAlphanumeric) return -1;
      if (!aIsAlphanumeric && bIsAlphanumeric) return 1;

      // Both are of the same type: sort alphabetically
      return a.localeCompare(b);
    });
  }

  const loadSections = async () => {
    if (!values.projectNumber) {
      // Display error message in UI
      // e.g., setError("Project number is required.");
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/api/GetSectionsbyProjectNumber/${values.projectNumber}`
      );
      const filteredAndSortedSections = customSort(response.data.results);

      const sectionObjects = filteredAndSortedSections.map(
        (section: string) => ({
          text: section,
          value: section,
        })
      );

      setSections(sectionObjects);
    } catch (err) {
      console.error(err);
      // Handle the error in the UI
      // e.g., setError("Failed to load sections.");
    }
  };

  const loadJobNumbers = async () => {
    if (!values.projectNumber && !values.section) return;
    axiosInstance
      .get(
        `/api/GetJobNumbersbyProjectSection/${values.projectNumber}/${values.section}`
      )
      .then((res) => {
        setJobNumbers(
          res.data.results.map((job: string) => {
            return {
              text: job,
              value: job,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (values.projectNumber) loadSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.projectNumber]);

  useEffect(() => {
    if (values.region) loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.region]);

  useEffect(() => {
    if (selectedProject && values.section) {
      loadJobNumbers();
    } else {
      setJobNumbers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, values.section]);

  useEffect(() => {
    loadRegions();
  }, []);

  const pages = [{ name: "Create Take Down", href: "/create", current: true }];

  return (
    <>
      {manualMode && (
        <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <div
            aria-hidden="true"
            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ffd280] to-[#ffa500] opacity-30"
            />
          </div>
          <div
            aria-hidden="true"
            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ffd280] to-[#ffa500] opacity-30"
            />
          </div>
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-yellow-600"
              aria-hidden="true"
            />
            <p className="text-sm leading-6 text-gray-900">
              <strong className="font-semibold">Manual Mode Active</strong>
              <svg
                viewBox="0 0 2 2"
                aria-hidden="true"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
              >
                <circle r={1} cx={1} cy={1} />
              </svg>
              Automated checks and validations are bypassed.
            </p>
            <button
              onClick={handleExitManualMode}
              className="flex-none rounded-full bg-yellow-600 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            >
              Exit Manual Mode <span aria-hidden="true">&rarr;</span>
            </button>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              onClick={handleExitManualMode}
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5 text-gray-900" />
            </button>
          </div>
        </div>
      )}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-8 mt-4">
        <nav aria-label="Breadcrumb" className="flex">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <a href="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon
                    aria-hidden="true"
                    className="h-5 w-5 flex-shrink-0"
                  />
                  <span className="sr-only">Home</span>
                </a>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    aria-hidden="true"
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                  />
                  <a
                    href={page.href}
                    aria-current={page.current ? "page" : undefined}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-4 flex flex-col items-center">
          <Formik
            initialValues={values}
            validationSchema={Yup.object().shape({
              region: Yup.string().required("Region is required"),
              type: Yup.string().required("Type is required"),
            })}
            onSubmit={handleSubmit}
          >
            {(formik: FormikProps<typeof values>) => (
              <form onSubmit={formik.handleSubmit} className="w-full">
                {/* Header */}
                <div className="border-b border-gray-200 pb-5 mb-5">
                  <div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mb-4 sm:mb-0">
                      Create Lot Take Down
                    </h1>
                    <div className="flex flex-col sm:flex-row sm:items-center">
                      <button
                        type="button"
                        className="mb-2 sm:mb-0 sm:mr-3 inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setIsManualModeDialogOpen(true)}
                      >
                        <ExclamationTriangleIcon
                          className="h-5 w-5 text-yellow-400 mr-2"
                          aria-hidden="true"
                        />
                        Manual Mode
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            confirm(
                              "Are you sure you want to cancel? Any changes will be lost."
                            )
                          ) {
                            navigate(-1);
                          }
                        }}
                        className="mb-2 sm:mb-0 sm:mr-3 inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Cancel
                      </button>
                      <button
                        id="createBtn"
                        type="submit"
                        disabled={loading}
                        className={`inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 ${
                          loading
                            ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                            : "bg-primary-500 text-white hover:brightness-150"
                        }`}
                        onClick={() => {
                          if (!values.addresses.length) {
                            setAddressError("At least 1 address is required.");
                          } else {
                            setAddressError("");
                          }
                          if (!values.projectName.length) {
                            setProjectError("Project Name is required");
                          } else {
                            setProjectError("");
                          }
                          if (!values.section) {
                            setSectionError("Section is required");
                          } else {
                            setSectionError("");
                          }
                        }}
                      >
                        {loading ? "Creating..." : "Create"}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="space-y-4 md:space-y-12">
                  {/* Project Details Section */}
                  <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-200 pb-12 md:grid-cols-3">
                    <div>
                      <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
                        Project Details
                      </h2>
                      <p className="text-left mt-1 text-sm leading-6 text-gray-600">
                        Enter the details for the lot take down.
                      </p>
                    </div>

                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-2 md:gap-y-8 sm:grid-cols-6 md:col-span-2">
                      {/* Region */}
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="region"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Region
                        </label>
                        <div className="mt-2">
                          <select
                            id="region"
                            name="region"
                            value={formik.values.region}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setValues({
                                ...values,
                                region: e.target.value,
                                projectName: "",
                                projectNumber: "",
                                section: "",
                              });
                              setSelectedProject(null);
                            }}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">Select a region</option>
                            {availableRegions.length > 0
                              ? availableRegions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                ))
                              : ["DFW", "Houston", "Austin", "San Antonio"].map(
                                  (region) => (
                                    <option key={region} value={region}>
                                      {region}
                                    </option>
                                  )
                                )}
                          </select>
                          {formik.errors.region && (
                            <p className="mt-2 text-sm text-red-600">
                              {formik.errors.region}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* Type */}
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="type"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Type
                        </label>
                        <div className="mt-2">
                          <select
                            id="type"
                            name="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">Select type</option>
                            <option value="cash">Cash</option>
                            <option value="interim">Interim</option>
                          </select>
                          {formik.errors.type && (
                            <p className="mt-2 text-sm text-red-600">
                              {formik.errors.type}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* Project Name */}
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="projectName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Project Name
                        </label>
                        <div className="mt-2">
                          {manualMode ? (
                            <input
                              type="text"
                              id="projectName"
                              name="projectName"
                              value={values.projectName}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setValues({
                                  ...values,
                                  projectName: e.target.value,
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          ) : (
                            <select
                              id="projectName"
                              name="projectName"
                              value={values.projectName}
                              onChange={(e) => {
                                formik.handleChange(e);
                                const selectedOption: any = projects.find(
                                  (project: any) =>
                                    project.projectName === e.target.value
                                );
                                setSelectedProject(selectedOption || null);
                                setValues({
                                  ...values,
                                  projectName: selectedOption
                                    ? selectedOption.projectName
                                    : "",
                                  projectNumber: selectedOption
                                    ? selectedOption.projectNumber
                                    : "",
                                  section: "",
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            >
                              <option value="">Select a project</option>
                              {projects.map((project: any) => (
                                <option
                                  key={project.projectId}
                                  value={project.projectName}
                                >
                                  {project.projectName}
                                </option>
                              ))}
                            </select>
                          )}
                          {projectError && (
                            <p className="mt-2 text-sm text-red-600">
                              {projectError}
                            </p>
                          )}
                        </div>
                      </div>

                      {/* Project Number */}
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="projectNumber"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Project Number
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="projectNumber"
                            name="projectNumber"
                            value={values.projectNumber}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setValues({
                                ...values,
                                projectNumber: e.target.value,
                              });
                            }}
                            disabled={!manualMode}
                            className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 ${
                              !manualMode ? "bg-gray-100" : ""
                            }`}
                          />
                          {projectError && (
                            <p className="mt-2 text-sm text-red-600">
                              Select a project name
                            </p>
                          )}
                        </div>
                      </div>

                      {/* Brix Project ID - only visible in manual mode */}
                      {manualMode && (
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="brixProjectId"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Brix Project ID
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              id="brixProjectId"
                              name="brixProjectId"
                              value={brixProjectId}
                              onChange={(e) => setBrixProjectId(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      )}

                      {/* Section */}
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="section"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Section
                        </label>
                        <div className="mt-2">
                          {manualMode ? (
                            <input
                              type="text"
                              id="section"
                              name="section"
                              value={values.section}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setValues({
                                  ...values,
                                  section: e.target.value,
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          ) : (
                            <select
                              id="section"
                              name="section"
                              value={values.section}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setValues({
                                  ...values,
                                  section: e.target.value,
                                });
                              }}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            >
                              <option value="">Select a section</option>
                              {sections.map((option: any) => (
                                <option key={option.value} value={option.value}>
                                  {option.text}
                                </option>
                              ))}
                            </select>
                          )}
                          {sectionError && (
                            <p className="mt-2 text-sm text-red-600">
                              {sectionError}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Addresses Section */}
                  <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-200 pb-12 md:grid-cols-3">
                    <div>
                      <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
                        Addresses
                      </h2>
                      <p className="text-left mt-1 text-sm leading-6 text-gray-600">
                        Add and manage the addresses for the lot take down.
                      </p>
                    </div>

                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 md:col-span-2">
                      {/* Addresses Accordion */}
                      <div className="col-span-full">
                        <div className="border rounded-md">
                          <button
                            type="button"
                            className="w-full flex items-center justify-between px-4 py-2 text-left text-sm font-medium text-gray-900 bg-gray-50 hover:bg-gray-100 focus:outline-none"
                            onClick={() =>
                              setValues((prevValues) => ({
                                ...prevValues,
                                addressesOpen: !prevValues.addressesOpen,
                              }))
                            }
                          >
                            <span>Addresses ({values.addresses.length})</span>
                            <span>{values.addressesOpen ? "-" : "+"}</span>
                          </button>
                          {values.addressesOpen && (
                            <div className="px-4 py-2">
                              {values.addresses.length > 0 ? (
                                values.addresses.map((address, index) => (
                                  <div
                                    key={index}
                                    className="grid grid-cols-1 gap-4 sm:grid-cols-12 mt-2"
                                  >
                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium text-gray-900">
                                        Address
                                      </label>
                                      <input
                                        type="text"
                                        value={address.address}
                                        disabled
                                        className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="sm:col-span-2">
                                      <label className="block text-sm font-medium text-gray-900">
                                        Job
                                      </label>
                                      <input
                                        type="text"
                                        value={address.jobNumber}
                                        disabled
                                        className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="sm:col-span-2">
                                      <label className="block text-sm font-medium text-gray-900">
                                        Lot
                                      </label>
                                      <input
                                        type="text"
                                        value={address.lot}
                                        disabled
                                        className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="sm:col-span-2">
                                      <label className="block text-sm font-medium text-gray-900">
                                        Block
                                      </label>
                                      <input
                                        type="text"
                                        value={address.block}
                                        disabled
                                        className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="sm:col-span-2">
                                      <label className="block text-sm font-medium text-gray-900">
                                        Price
                                      </label>
                                      <input
                                        type="text"
                                        value={address.price}
                                        disabled
                                        className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                    <div className="flex items-center align-center h-20">
                                      <button
                                        type="button"
                                        className="cursor-pointer text-red-600 hover:text-red-900"
                                        onClick={() => {
                                          setValues({
                                            ...values,
                                            addresses: [
                                              ...values.addresses.slice(
                                                0,
                                                index
                                              ),
                                              ...values.addresses.slice(
                                                index + 1
                                              ),
                                            ],
                                          });
                                        }}
                                      >
                                        <TrashIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="p-4">
                                  <p className="text-gray-500 mt-2">
                                    No addresses added yet.
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Add New Address */}
                      <div className="col-span-full">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-12">
                          {/* Address */}
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="newAddress"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              id="newAddress"
                              name="address"
                              placeholder="Address"
                              value={addressState.address}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue.length > 50) {
                                  setShowMaxCharWarning(true);
                                } else {
                                  setShowMaxCharWarning(false);
                                  setAddressState({
                                    ...addressState,
                                    address: newValue,
                                  });
                                }
                              }}
                              className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          {/* Job Number */}
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="jobNumber"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Job
                            </label>
                            {manualMode ? (
                              <input
                                type="text"
                                id="jobNumber"
                                name="jobNumber"
                                placeholder="Job Number"
                                value={addressState.jobNumber}
                                onChange={(e) =>
                                  setAddressState({
                                    ...addressState,
                                    jobNumber: e.target.value,
                                  })
                                }
                                disabled={!values.projectName}
                                className={`mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                                  !values.projectName
                                    ? "bg-gray-100 cursor-not-allowed"
                                    : ""
                                }`}
                              />
                            ) : (
                              <select
                                id="jobNumber"
                                name="jobNumber"
                                value={addressState.jobNumber}
                                onChange={(e) =>
                                  setAddressState({
                                    ...addressState,
                                    jobNumber: e.target.value,
                                  })
                                }
                                disabled={!values.projectName}
                                className={`mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                                  !values.projectName
                                    ? "bg-gray-100 cursor-not-allowed"
                                    : ""
                                }`}
                              >
                                <option value="">Job</option>
                                {jobNumbers.map((job) => (
                                  <option key={job.value} value={job.value}>
                                    {job.text}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>

                          {/* Lot */}
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="lot"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Lot
                            </label>
                            <input
                              type="text"
                              id="lot"
                              name="lot"
                              placeholder="Lot"
                              value={addressState.lot}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue.length > 5) {
                                  setShowMaxCharWarning(true);
                                } else {
                                  setShowMaxCharWarning(false);
                                  if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                                    setAddressState({
                                      ...addressState,
                                      lot: newValue,
                                    });
                                  }
                                }
                              }}
                              className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          {/* Block */}
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="block"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Block
                            </label>
                            <input
                              type="text"
                              id="block"
                              name="block"
                              placeholder="Block"
                              value={addressState.block}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (newValue.length > 5) {
                                  setShowMaxCharWarning(true);
                                } else {
                                  setShowMaxCharWarning(false);
                                  if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                                    setAddressState({
                                      ...addressState,
                                      block: newValue,
                                    });
                                  }
                                }
                              }}
                              className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          {/* Price */}
                          <div className="sm:col-span-2">
                            <label
                              htmlFor="price"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Price
                            </label>
                            <input
                              type="text"
                              id="price"
                              name="price"
                              placeholder="Price"
                              value={addressState.price}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(newValue)) {
                                  setAddressState({
                                    ...addressState,
                                    price: newValue,
                                  });
                                }
                              }}
                              className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          {/* Add Button */}
                          <div className="sm:col-span-1 flex items-end">
                            <button
                              type="button"
                              id="addAddress"
                              className="cursor-pointer w-full inline-flex items-center  sm:mb-0 sm:mr-3  justify-center rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => {
                                setShowMaxCharWarning(false);
                                setValues({
                                  ...values,
                                  addresses: [
                                    ...values.addresses,
                                    {
                                      address: addressState.address.trim(),
                                      lot: addressState.lot.trim(),
                                      block: addressState.block.trim(),
                                      price: addressState.price,
                                      jobNumber: addressState.jobNumber,
                                    },
                                  ],
                                });
                                setAddressState({
                                  address: "",
                                  lot: "",
                                  block: "",
                                  price: "",
                                  jobNumber: "",
                                });
                                setAddressError("");
                              }}
                              disabled={
                                !addressState.address.trim() ||
                                !addressState.lot.trim() ||
                                !addressState.jobNumber.trim()
                              }
                            >
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </div>

                          {!values.projectName && (
                            <div className="sm:col-span-12">
                              <p className="mt-2 text-sm text-red-600">
                                Select a project to enable job number selection.
                              </p>
                            </div>
                          )}

                          {/* Error Messages */}
                          {addressError && (
                            <div className="sm:col-span-6">
                              <p className="mt-2 text-sm text-red-600">
                                {addressError}
                              </p>
                            </div>
                          )}

                          {showMaxCharWarning && (
                            <div className="sm:col-span-6">
                              <p className="mt-2 text-sm text-red-600">
                                Address must be 50 characters or less. Lot and
                                Block must be 5 characters or less.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Additional Information Section */}
                  <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
                    <div>
                      <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
                        Additional Information
                      </h2>
                      <p className="text-left mt-1 text-sm leading-6 text-gray-600">
                        Add comments and attachments related to the lot take
                        down.
                      </p>
                    </div>

                    <div className="max-w-3xl md:col-span-2">
                      {/* Tabs for Comments and Attachments */}
                      <div>
                        <div className="border-b border-gray-200">
                          <nav
                            className="-mb-px flex space-x-8"
                            aria-label="Tabs"
                          >
                            <button
                              type="button"
                              onClick={() => setActiveTab("Comments")}
                              className={classNames(
                                activeTab === "Comments"
                                  ? "border-primary-500 text-primary-600"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                              )}
                            >
                              Comments
                            </button>
                            <button
                              type="button"
                              onClick={() => setActiveTab("Attachments")}
                              className={classNames(
                                activeTab === "Attachments"
                                  ? "border-primary-500 text-primary-600"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                              )}
                            >
                              Attachments
                            </button>
                          </nav>
                        </div>
                        <div className="mt-4">
                          {activeTab === "Comments" && (
                            <div className="bg-white  py-8 lg:py-16 antialiased">
                              <div className="max-w-2xl mx-auto px-4">
                                <div className="flex justify-between items-center mb-6">
                                  <h2 className="text-lg lg:text-2xl font-bold text-gray-900 ">
                                    Comments ({comments.length})
                                  </h2>
                                </div>

                                {/* Comment input form */}
                                <div className="mb-6">
                                  <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 ">
                                    <label
                                      htmlFor="comment"
                                      className="sr-only"
                                    >
                                      Your comment
                                    </label>
                                    <textarea
                                      id="comment"
                                      rows={6}
                                      className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
                                      placeholder="Write a comment..."
                                      value={newComment}
                                      onChange={(e) =>
                                        setNewComment(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                  <div className="flex justify-end">
                                    <button
                                      type="button"
                                      onClick={addComment}
                                      className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-500 rounded-lg focus:ring-4 focus:ring-primary-200  hover:brightness-150"
                                    >
                                      Post comment
                                    </button>
                                  </div>
                                </div>

                                {/* Comments list */}
                                {comments.map((comment, index) => (
                                  <article
                                    key={index}
                                    className="p-6 mb-3 text-base bg-white border-t border-gray-200"
                                  >
                                    <footer className="flex justify-between items-center mb-2">
                                      <div className="flex items-center">
                                        <p className="inline-flex items-center mr-3 text-sm text-gray-900  font-semibold">
                                          {userPhoto ? (
                                            <img
                                              className="mr-2 w-6 h-6 rounded-full"
                                              src={userPhoto}
                                              alt={`${
                                                account?.name || "User"
                                              }'s Avatar`}
                                            />
                                          ) : (
                                            <UserCircleIcon
                                              className="mr-2 w-6 h-6 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          )}
                                          {account?.username || "Anonymous"}
                                        </p>
                                        <p className="text-sm  text-gray-600">
                                          <time
                                            dateTime={new Date().toISOString()}
                                            title={new Date().toLocaleDateString()}
                                          >
                                            {new Date().toLocaleDateString()}
                                          </time>
                                        </p>
                                      </div>
                                      <button
                                        className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500  bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                                        type="button"
                                        onClick={() => removeComment(index)}
                                      >
                                        <TrashIcon className="w-5 h-5" />
                                        <span className="sr-only">
                                          Comment settings
                                        </span>
                                      </button>
                                    </footer>
                                    <p className="text-gray-500 text-left w-full">
                                      {comment}
                                    </p>
                                  </article>
                                ))}
                              </div>
                            </div>
                          )}
                          {activeTab === "Attachments" && (
                            <div className="bg-white  py-8 lg:py-16 antialiased">
                              <div className="max-w-2xl mx-auto px-4">
                                <div className="flex justify-between items-center mb-6">
                                  <h2 className="text-lg lg:text-2xl font-bold text-gray-900">
                                    Attachments ({attachments.length})
                                  </h2>
                                </div>

                                {/* File drop area */}
                                <div {...getRootProps()} className="mb-6">
                                  <input {...getInputProps()} />
                                  <div className="py-8 px-4 border-2 border-dashed border-gray-300 rounded-lg text-center cursor-pointer hover:border-primary-500 transition-colors">
                                    {isDragActive ? (
                                      <p className="text-primary-500">
                                        Drop the files here ...
                                      </p>
                                    ) : (
                                      <p className="text-gray-500">
                                        Drag 'n' drop some files here, or click
                                        to select files
                                      </p>
                                    )}
                                  </div>
                                </div>

                                {/* Attachment list */}
                                {attachments.map((attachment, index) => (
                                  <article
                                    key={index}
                                    className="p-6 mb-3 text-base bg-white border-t border-gray-200"
                                  >
                                    <footer className="flex justify-between items-center mb-2">
                                      <div className="flex items-center">
                                        <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold">
                                          {userPhoto ? (
                                            <img
                                              className="mr-2 w-6 h-6 rounded-full"
                                              src={userPhoto}
                                              alt={`${
                                                account?.name || "User"
                                              }'s Avatar`}
                                            />
                                          ) : (
                                            <UserCircleIcon
                                              className="mr-2 w-6 h-6 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          )}
                                          {account?.username || "Anonymous"}
                                        </p>
                                        <p className="text-sm text-gray-600">
                                          <time
                                            dateTime={new Date(
                                              attachment.lastModified
                                            ).toISOString()}
                                          >
                                            {new Date(
                                              attachment.lastModified
                                            ).toLocaleDateString()}
                                          </time>
                                        </p>
                                      </div>
                                      <button
                                        className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500  bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                                        type="button"
                                        onClick={() => {
                                          setAttachments(
                                            attachments.filter(
                                              (_, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        <TrashIcon className="w-5 h-5" />
                                        <span className="sr-only">
                                          Remove attachment
                                        </span>
                                      </button>
                                    </footer>
                                    <div className="flex items-center mt-2">
                                      <PaperClipIcon className="w-5 h-5 mr-2 text-gray-500" />
                                      <p className="text-gray-900 font-medium">
                                        {attachment.name}
                                      </p>
                                    </div>
                                    <p className="text-gray-500 text-left w-full mt-1">
                                      {(attachment.size / 1024 / 1024).toFixed(
                                        2
                                      )}{" "}
                                      MB
                                    </p>
                                  </article>
                                ))}

                                {attachments.length === 0 && (
                                  <p className="text-gray-500 text-center">
                                    No attachments yet
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Comment and Attachment Input */}
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                {/* <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className={`rounded-md  px-3 py-2 text-sm font-semiboldshadow-sm bg-primary-500 text-white hover:brightness-150 ${
                    loading ? "cursor-not-allowed opacity-50" : ""
                  } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600`}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </div> */}
              </form>
            )}
          </Formik>
        </div>

        {/* Manual Mode Warning Dialog */}
        <Dialog
          open={isManualModeDialogOpen}
          onClose={() => setIsManualModeDialogOpen(false)}
          aria-labelledby="manual-mode-dialog-title"
          aria-describedby="manual-mode-dialog-description"
        >
          <DialogTitle id="manual-mode-dialog-title">
            <div className="flex items-center">
              <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600 mr-2" />
              Manual Mode Warning
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="manual-mode-dialog-description">
              Activating Manual Mode bypasses automated checks and validations.
              This can lead to data inconsistencies and potential errors. Only
              proceed if you are absolutely certain of your actions and
              understand the implications.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => setIsManualModeDialogOpen(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setIsManualModeDialogOpen(false);
                setManualMode(true);
              }}
              className="bg-primary-500 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:brightness-150 focus:outline-none"
            >
              Activate Manual Mode
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
